/**
 * Please keep here only features flags names which are currently in use.
 */
export default {
	FINANCE_INVOICE_REACT: "ui.financeInvoiceReact",
	NEW_MOBILE_PROFILE: "ui.newMobileProfile",
	HCL_ADMINISTRATION_UI_PRODUCTS: "ui.hcl.administrationUi.products",
	NEW_FORM_DEFINITION_LIST_MENU_ITEM: "ui.newFormDefinitionListMenuItemForTest",
	NEW_FORM_DEFINITION_LIST_URL: "ui.newFormDefinitionListMenuItemUrl"
};
